import React from 'react'
// import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import * as sanitizeHtml from 'sanitize-html-react'

import playSvg from '../img/play.svg'
import pauseSvg from '../img/pause.svg'
// import stopSvg from '../img/stop.svg'
import ffwdSvg from '../img/ffwd.svg'
import rwdSvg from '../img/rwd.svg'

function getTime(time) {
  if (!isNaN(time)) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
  } else {
    return '0:00'
  }
}

const PodcastsWithData = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startAt: 0,
      showNext: 3,
      trackId: '',
      selectedTrack: null,
      player: 'stopped',
      currentTime: null,
      duration: null,
      sliceAt: [20, 20, 20, 20, 20, 20]
    }
    this.player = null
    this.layout = [
      'grs1 gre2 gcs1 gce2-l gce3',
      'grs1-l gre2-l grs2 gre3 gcs2-l gce3-l gcs1 gce3',
      'grs1-l gre2-l grs3 gre4 gcs3-l gce4-l gcs1 gce3',
      'grs2-l gre3-l grs4 gre5 gcs1 gce2-l gce3',
      'grs2-l gre3-l grs5 gre6 gcs2-l gcs1 gce3',
      'grs2-l gre3-l grs6 gre7 gcs3-l gce4-l gcs1 gce3'
    ]
  }

  componentDidMount() {
    this.player.addEventListener('timeupdate', e => {
      this.setState({
        currentTime: e.target.currentTime,
        duration: e.target.duration
      })
    })
    if (this.props.podcasts !== null && this.props.podcasts !== undefined) {
      console.log(this.props.podcasts)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.podcasts !== prevProps.podcasts) {
      console.log(this.props.podcasts)
    }
    if (this.state.selectedTrack !== prevState.selectedTrack) {
      let track = this.state.selectedTrack
      if (track) {
        this.player.src = track
        this.player.play()
        this.setState({ player: 'playing', duration: this.player.duration })
      }
    }
    if (this.state.player !== prevState.player) {
      if (this.state.player === 'paused') {
        this.player.pause()
      } else if (this.state.player === 'stopped') {
        this.player.pause()
        this.player.currentTime = 0
        this.setState({ selectedTrack: null })
      } else if (
        this.state.player === 'playing' &&
        prevState.player === 'paused'
      ) {
        this.player.play()
      } else if (
        this.state.player === 'rewind' ||
        this.state.player === 'forward'
      ) {
        if (this.state.player === 'rewind') {
          this.player.currentTime -= 10.0
        } else {
          this.player.currentTime += 10.0
        }
        if (prevState.player === 'playing') {
          this.player.play()
          this.setState({ player: 'playing' })
        } else if (prevState.player === 'paused') {
          this.setState({ player: 'paused' })
        }
      }
    }
  }

  componentWillUnmount() {
    this.player.removeEventListener('timeupdate', () => {})
  }

  readMore(idx, fullContent, more) {
    const maxShown = this.state.sliceAt.reduce((a, b) => (a > b ? a : b))
    const toReduce = this.state.sliceAt.findIndex(a => a === maxShown)
    const stepDown = []
    if (maxShown > 20) {
      for (let index = maxShown - 1; index >= 20; index--) {
        stepDown.push(index)
      }
    }
    const stepUp = []
    if (more) {
      const fullArrLen = sanitizeHtml(fullContent)
        .toString()
        .split(' ').length
      for (let index = 20; index <= fullArrLen; index++) {
        stepUp.push(index)
      }
    }
    if (stepDown === []) {
      this.changeSlice(stepUp, idx, 30, null, null)
    } else {
      this.changeSlice(stepDown, toReduce, 20, stepUp, idx)
    }
  }

  changeSlice(theArr, idx, waitTime, secondArr, secondIdx) {
    if (theArr.length > 0) {
      setTimeout(() => {
        let sliceArr = []
        for (let index = 0; index < 6; index++) {
          if (index === idx) {
            sliceArr.push(theArr[0])
          } else {
            sliceArr.push(20)
          }
        }
        this.setState({ sliceAt: sliceArr })
        theArr.shift()
        this.changeSlice(theArr, idx, waitTime, secondArr, secondIdx)
      }, waitTime)
    } else if (secondArr !== null && secondArr !== []) {
      this.changeSlice(secondArr, secondIdx, waitTime, null, null)
    } else {
      return
    }
  }

  render() {
    return (
      <>
        <audio ref={ref => (this.player = ref)} />
        <div className="grid w-100 gc3-l">
          {this.props.podcasts.allAnchorEpisode.edges
            .slice(this.state.startAt, this.state.startAt + this.state.showNext)
            .map((onepodcast, idx) => (
              <div
                key={onepodcast.node.id}
                className={this.layout[idx] + ' ma2 bg-ski-pinkpurple pa4-ns pa2'}
              >
                <div className="fw6 pb3 ttu">
                  {onepodcast.node.title}{' '}
                </div>
                <div className="">
                <div
                    className="pointer dib pr5-ns"
                    onClick={evt => {
                      evt.preventDefault()
                      if (this.state.player !== 'playing') {
                        this.setState({
                          trackId: onepodcast.node.id,
                          selectedTrack: onepodcast.node.enclosure.url
                        })
                        if (this.state.player === 'paused') {
                          this.setState({ player: 'playing' })
                        }
                      } else {
                        this.setState({ player: 'paused' })
                      }
                    }}
                  >
                    {this.state.player === 'playing' &&
                    this.state.trackId === onepodcast.node.id ? (
                      <>
                        <div
                          className="w1 h1 mr1 dib"
                          style={{ backgroundImage: `url(${pauseSvg})` }}
                        />
                      </>
                    ) : (
                      <div
                        className="w1 h1 mr1"
                        style={{ backgroundImage: `url(${playSvg})` }}
                      ></div>
                    )}
                  </div>
                  {(this.state.player === 'playing' ||
                    this.state.player === 'paused') &&
                  this.state.trackId === onepodcast.node.id ? (
                    <>
                      <div
                        className="pointer dib gray pr2"
                        onClick={() => {
                          this.setState({ player: 'rewind' })
                        }}
                      >
                        <div
                          className="w1 h1 dib"
                          style={{ backgroundImage: `url(${rwdSvg})` }}
                        ></div>
                      </div>
                      {/* <div
                        className="pointer dib gray pr3"
                        onClick={() => {
                          this.setState({ player: 'stopped' })
                        }}
                      >
                        <div
                          className="w1 h1 mr1 dib"
                          style={{ backgroundImage: `url(${stopSvg})` }}
                        ></div>{' '}
                      </div> */}
                    </>
                  ) : null}
                  

                  {(this.state.player === 'playing' ||
                    this.state.player === 'paused') &&
                  this.state.trackId === onepodcast.node.id ? (
                    <>
                      <div className="dib gray mb2 pr2">
                        {this.state.currentTime !== null
                          ? getTime(this.state.currentTime) +
                            ' / ' +
                            getTime(onepodcast.node.enclosure.length / 20000)
                          : '(loading)'}
                      </div>
                    </>
                  ) : null}
                  {(this.state.player === 'playing' ||
                    this.state.player === 'paused') &&
                  this.state.trackId === onepodcast.node.id ? (
                    <>
                      <div
                        className="pointer dib gray pr3"
                        onClick={() => {
                          this.setState({ player: 'forward' })
                        }}
                      >
                        <div
                          className="w1 h1 mr1 dib"
                          style={{ backgroundImage: `url(${ffwdSvg})` }}
                        ></div>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="ski-dark-silver">{onepodcast.node.pubDate}</div>
                <div className="pb2">
                  <span
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(
                        onepodcast.node.content
                          .toString()
                          .split(' ')
                          .slice(0, this.state.sliceAt[idx])
                          .join(' ') + '...',
                        {
                          allowedTags: ['b', 'i', 'em', 'strong', 'a'],
                          allowedAttributes: {
                            a: ['href']
                          }
                        }
                      )
                    }}
                  />
                  <span
                    className="pointer ski-verydark-purple fw5 i"
                    onClick={() => {
                      this.readMore(
                        idx,
                        onepodcast.node.content,
                        this.state.sliceAt[idx] === 20
                      )
                    }}
                  >
                    {this.state.sliceAt[idx] === 20
                      ? ' Read More'
                      : ' Read Less'}
                  </span>
                </div>

                {/* <div dangerouslySetInnerHTML={{__html: onepodcast.node.content}} /> */}
                {/* <div className="">{onepodcast.node.content}</div> */}
              </div>
            ))}
        </div>
        <div className="grid w-100 gc2">
          <div className="gcs1 gce2 grs1 gre2">
            {this.state.startAt === 0 ? (
              <div className="tc center pv2 ski-medium-silver ">Newer Episodes</div>
            ) : (
              <div
                className="tc pv2 ph3 center grow ski-dark-silver pointer"
                onClick={() => {
                  this.setState({
                    startAt: Math.max(0, this.state.startAt - 6)
                  })
                }}
              >
                Newer Episodes
              </div>
            )}
          </div>
          <div className="gcs2 gce3 grs1 gre2">
            {this.state.startAt + this.state.showNext >=
            this.props.podcasts.allAnchorEpisode.edges.length ? (
              <div className="tc center pv2 ski-medium-silver ">Older Episodes</div>
            ) : (
              <div
                className="tc pv2 ph3 center ski-dark-silver grow pointer"
                onClick={() => {
                  this.setState({
                    startAt: Math.min(
                      this.props.podcasts.allAnchorEpisode.edges.length - 5,
                      this.state.startAt + 6
                    )
                  })
                }}
              >
                Older Episodes
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

const Podcasts = () => (
  <StaticQuery
    query={graphql`
      query PodcastsRss {
        allAnchorEpisode {
          edges {
            node {
              id
              title
              content
              pubDate
              enclosure {
                length
                url
              }
            }
          }
        }
      }
    `}
    render={data => <PodcastsWithData podcasts={data} />}
  />
)

export default Podcasts
