import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PreviewCompatibleImage from './../components/PreviewCompatibleImage'

import Layout from '../components/Layout'

import podcastAngleSVG from '../img/podcast-angle-bg.svg'

import Markdown from 'markdown-to-jsx'
import Podcasts from '../components/Podcasts'
import ContactPopup from '../components/ContactPopup'

export const PodcastPageTemplate = ({
  profilepicture,
  primaryheadline,
  podcast,
  introblock,
  podcastepisodes,
  podcastblurb,
  subscribelinks,
  businesscoach,
  ebook,
}) => {
  const [popupConfig, setPopupConfig] = React.useState({})
  const [popupButton, setPopupButton] = React.useState('')

  return (
    <>
      {JSON.stringify(popupConfig) === '{}' ? null : (
        <ContactPopup
          callback={(e) => {
            if (e === false) {
              setPopupConfig({})
            }
          }}
          cc={popupConfig}
          buttonText={popupButton}
        />
      )}
      <div>
        {/* profilepicture & primaryheadline */}
        <div className="w-100 gc-podcast grid portfolio-pt-ns">
          <div className="gcs2-ns gcs1 gce5 grs1 gre2 pb0-ns pb5 z-2 mb-1px">
            <PreviewCompatibleImage
              imageInfo={{
                image: profilepicture.image,
                alt: profilepicture.alt,
              }}
            />
          </div>
          <div
            className="db-ns dn gcs1 gce5 grs1 gre2 z-999 bg-100wh"
            style={{ backgroundImage: `url(${podcastAngleSVG})` }}
          ></div>
          <div className="gcs1 gce3-ns gce5 grs1-ns grs2 gre2-ns gre3 g-self-end z-999">
            <div className="w-100 pb3 ph0-ns ph3">
              <div className="tr-l tc pb4 ski-dark-purple f2-l f3 tracked ph4-ns ph3 ph5-m">
                The <span className="fw5 ttu">supernova</span>
                <br />
                <span className="cursive f1-l f2">Podcast</span>
              </div>
              <div className="ml5-ns tl-ns tc">
                <div className="fw5 f4 f5-m tracked-mega-ns tracked ski-dark-silver pr3-m">
                  {podcast.title}
                </div>
                <br />
                <div className="pb4-ns pb3 pb2-m fw4 f5 lh-copy tracked">
                  {primaryheadline.description}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* podcast statement */}
        <div className="ph6-ns ph4-m pt4-ns">
          <div className="tc lh-copy ph6-ns ph3-m ph4 pb4-ns pt2">
            <div className="fw5 f3-ns f5  ski-verydark-purple tracked">
              <Markdown>{podcast.description}</Markdown>
            </div>
          </div>
        </div>

        {/* introblock: prepping for tomorrow */}
        <div className="mh6-ns mh4-m b--ski-purple bw1-ns bt-ns bl-ns bb-ns br-ns ph4-ns ph2-m ph3 pv3 mb4">
          <div className="tc f2-ns f3 fw2 tracked pb3 pt4 ski-purple  cursive">
            {introblock.title}
          </div>
          <div className="pt3-markup no-hr tc fw4 f4-ns f5 lh-copy pb4-ns ph5-ns ph3-m">
            <Markdown>{introblock.description}</Markdown>
          </div>
        </div>

        {/* podcast episodes */}
        <div className="w-100 pt4 pb2-ns pb4 bg-ski-verylight-silver">
          <div className="tc ttu f2-ns f3 fw6 ski-medium-silver-ns ski-dark-silver tracked">
            {podcastepisodes.title}
          </div>
          <div className="tc f4-ns f5 fw5 pv3 ski-dark-silver-ns tracked">
            {podcastepisodes.subtitle}
          </div>
          <div className="ph5-ns lh-copy">
            <Podcasts />
          </div>
        </div>

        {/* podcastblurb */}
        <div className="tc f2-ns f3 pt4 pb4 ph6-ns ph4-m tracked ski-verydark-purple cursive">
          {podcastblurb}
        </div>

        {/* subscribelinks */}
        <div className="ph5-ns ph0-m ph2">
          <div className="tc bg-ski-light-bluepurple">
            <div className="grid gc2 subscribe-grid-m">
              <div className="gcs1 gce2-ns gce3 grs1 gre2 gre3-m h-100 grid">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: subscribelinks.image,
                    alt: subscribelinks.alt,
                    imgClass: 'left-cover-force',
                  }}
                />
              </div>
              <div className="gcs2-ns gcs1 gce3 grs1-ns grs2 gre2-l gre3 tc grid">
                <div className="self-center">
                  <div className="pb0 pt4-ns pt5 white ttu fw6 f2 tracked-mega lh-copy">
                    SUBSCRIBE
                  </div>
                  <div className="pb0 f3 tracked ski-dark-bluepurple lh-solid">
                    <span className="white">and</span> never miss a show
                  </div>
                  <div className="pt4 pb4 ph6-ns ph5-m ph3 f5 lh-copy">
                    <Markdown>{subscribelinks.blurb}</Markdown>
                  </div>
                  <div className="grid grg2 gc1 gafr ph6-ns ph5-m ph4 gji-stretch">
                    {subscribelinks.buttons
                      .slice(0, 3)
                      .map((onebutton, idx) => (
                        <div
                          key={'button' + idx}
                          className={
                            'shadow-1 grow gcs1 gce2 pointer link tc tracked ph3 ph4-m pv2 black bg-ski-verylight-blue grs' +
                            (idx + 1) +
                            ' gre' +
                            (idx + 2) +
                            (idx === 2 ? ' mb5' : '')
                          }
                          onClick={() => {
                            window.open(onebutton.link, '_blank')
                          }}
                        >
                          {onebutton.text}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* podcast host bio */}
        <div className="tc pt2-ns mt4-ns mt2">
          <div className="grid gc2">
            <div className="gcs1 gce3 grs1 gre2 h3"></div>
            <div className="bg-ski-verylight-silver grs2 gre4 gre5-m gcs1 gce3"></div>
            <div className="gcs2-ns gcs1 gce3 grs1 gre3-ns gre2 pl4-l pr6-l pl5-m pr4-m">
              <PreviewCompatibleImage
                imageInfo={{
                  image: businesscoach.image,
                  alt: businesscoach.alt,
                }}
              />
            </div>
            <div className="gcs1 gce2 grs2 gre3 nr5 ph4 ph5-m grid">
              <div className="db-m dn tc ski-dark-purple fw6 f3 lh-copy tracked self-center pv4">
                {businesscoach.title}
              </div>
            </div>
            <div className="gcs1 gce2-l gce3 grs2-l grs3-m grs2 gre3-ns gre4 pt3-ns pt4 pt4-m self-end ph5-ns ph4">
              <div className="dn-m tc ski-dark-purple fw6 f3 lh-copy tracked pl5-ns pt4-ns pb3-ns pb4">
                {businesscoach.title}
              </div>
              <div className="tj-ns tc fw3 tracked-ns lh-copy pl5-l pt3-markup">
                <Markdown>{businesscoach.description}</Markdown>
              </div>
            </div>
          </div>
          <div className="tc bg-ski-verylight-silver pb4 pt4 ph0-ns ph5-m ph3 lh-copy">
            <span className="no-hr fw4 f4-ns f5 tracked ski-dark-silver">
              <Markdown>{businesscoach.greentext}</Markdown>
            </span>
          </div>
        </div>

        {/* ebook */}
        <div className="tc pt2-ns pb4 ph6-l ph5-m ph2 mt4-ns grid gc2">
          <div className="gcs1 gce2-ns gce3 grs2-l grs1 gre3-l gre2 pr3-ns pb0-ns pb4 pt2 v-mid">
            <PreviewCompatibleImage
              imageInfo={{
                image: ebook.image,
                alt: ebook.alt,
              }}
            />
            <div className="db dn-ns gcs1 gce3 grs1-ns grs2 gre2-ns gre3 ski-dark-purple pt4 f3 tracked cursive">
              {ebook.title}
              <br />
              <button
                className="grow pointer center link tc fw4 f6 ph5-ns tracked ml-auto mr0 ph3 pv2 helvetica bg-ski-dark-purple white bn shadow-1 mt3 nb2 br3"
                onClick={(event) => {
                  event.preventDefault()
                  setPopupButton('Send')
                  setPopupConfig({
                    fullName: 1,
                    email: 2,
                    heading: 'Ebook Relaunch Notification',
                  })
                }}
              >
                <span className="ttu fw6">Relaunching 2021</span>
                <br />
                Notify me
              </button>
            </div>
          </div>
          <div className="gcs2 gce3 grs1 gre2 grid">
            <div className="db-m dn ski-dark-purple f2 tracked cursive self-center ph3">
              {ebook.title}
              <br />
              <button
                className="grow pointer center link tc fw4 f6 ph5-ns tracked ml-auto mr0 ph3 pv2 helvetica bg-ski-dark-purple white bn shadow-1 mv3 br3"
                onClick={(event) => {
                  event.preventDefault()
                  setPopupButton('Send')
                  setPopupConfig({
                    fullName: 1,
                    email: 2,
                    heading: 'Ebook Relaunch Notification',
                  })
                }}
              >
                <span className="ttu fw6">Relaunching 2021</span>
                <br />
                Notify me
              </button>
            </div>
          </div>
          <div className="gcs2-l gcs1 gce3 grs2-ns gre3-ns grs3 gre4 pl4-l grid pt3-m">
            <div className="db-l dn gcs1 gce3 grs1-ns grs2 gre2-ns gre3 ski-dark-purple pt0 pt4 pb0-ns pb4 f2-ns f3 tracked cursive">
              {ebook.title}
              <br />
              <button
                className="grow pointer center link tc fw4 f6 ph5-ns tracked ml-auto mr0 ph3 pv2 helvetica bg-ski-dark-purple white bn shadow-1 mv3 br3"
                onClick={(event) => {
                  event.preventDefault()
                  setPopupButton('Send')
                  setPopupConfig({
                    fullName: 1,
                    email: 2,
                    heading: 'Ebook Relaunch Notification',
                  })
                }}
              >
                <span className="ttu fw6">Relaunching 2021</span>
                <br />
                Notify me
              </button>
            </div>
            <div className="self-center pt3-m tj f5-ns f5 fw4 pt3-markup lh-copy ph0-ns ph4-m ph3">
              <div className="pb4">
                <div className="fw3 f4-ns f5">
                  <Markdown>{ebook.introheading}</Markdown>
                </div>
                <div className="pl2-ns lh-copy">
                  <Markdown>{ebook.introlist}</Markdown>
                </div>
                <Markdown>{ebook.introcta}</Markdown>
              </div>
              <div className="tj ski-dark-purple lh-copy pt2-markup">
                <Markdown>{ebook.description}</Markdown>
                <a
                  className="dib-m pl2 tj-ns tc pt0-ns pt2 i black lh-copy pt3-markup"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.amazon.com/Start-bang-then-glimmer-SuperNova-ebook/dp/B00MNCBCK0"
                >
                  <Markdown>{ebook.link}</Markdown>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

PodcastPageTemplate.propTypes = {
  profilepicture: PropTypes.object,
  primaryheadline: PropTypes.object,
  podcast: PropTypes.object,
  introblock: PropTypes.object,
  podcastepisodes: PropTypes.object,
  podcastblurb: PropTypes.string,
  subscribelinks: PropTypes.object,
  businesscoach: PropTypes.object,
  ebook: PropTypes.object,
}

const PodcastPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      seo={{
        title: 'Lena Ski - SuperNova Podcast',
        keywords: [
          `Global thought leaders and practitioners`,
          `Education empowerment`,
          `creating, measuring and communicating brand value`,
          `support purpose-centred-entrepreneurs in their quest to greatness`,
        ],
        description:
          'Coaching companies who are developing innovative and adaptive products. Giving them short-term and long-term goals to implement sustainable thinking',
      }}
      page="podcast"
    >
      <PodcastPageTemplate
        profilepicture={frontmatter.profilepicture}
        primaryheadline={frontmatter.primaryheadline}
        podcast={frontmatter.podcast}
        introblock={frontmatter.introblock}
        podcastepisodes={frontmatter.podcastepisodes}
        podcastblurb={frontmatter.podcastblurb}
        subscribelinks={frontmatter.subscribelinks}
        businesscoach={frontmatter.businesscoach}
        ebook={frontmatter.ebook}
      />
    </Layout>
  )
}

PodcastPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PodcastPage

export const pageQuery = graphql`
  query PodcastPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/podcast/" }) {
      frontmatter {
        title
        profilepicture {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
        primaryheadline {
          title
          description
        }
        podcast {
          alt
          description
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          title
        }
        introblock {
          title
          description
        }
        podcastepisodes {
          title
          subtitle
        }
        podcastblurb
        subscribelinks {
          title
          subtitle
          blurb
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
          buttons {
            text
            link
          }
        }
        businesscoach {
          title
          greentext
          description
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
          blurb
        }
        ebook {
          title
          introheading
          introlist
          introcta
          description
          link
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
      }
    }
  }
`
